import Categories from "../../Categories";
import OptimizeInfo from "../../OptimizeInfo";

export default function Optimize() {
    return(
        <>
            <OptimizeInfo />
            <Categories />
        </>
    )
}