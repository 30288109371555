import React from 'react';

export default function Browse() {
  return (
    <section id="browse" className="browse">
      <div className="browse--background"></div>
      <div className="trending--with--button">
        <div className="trending">
          <span className="primary-text">FNAF</span> is trending
        </div>
        <button className="btn--yt">
          <span>YouTube</span>
          <img className="logo--yt" src="./img/ytlogo.png" alt="Logo" />
        </button>
      </div>
      <div className="video--container">
        <button className="video--card video--card1">
          <img className="tn1" src="./img/image 4.png" alt="thumbnail1" />
          <span className="video-title">WARNING: SCARIEST GAME IN YEARS | Five Nights at Freddy's - Part 1</span>
          <img className="pfp" src="./img/image 9.png" alt="pfp" />
          <span className="extra--info">Markiplier | 113M views | 9 years ago</span>
        </button>
        <button className="video--card video--card2">
          <img className="tn1" src="./img/image 12.png" alt="thumbnail2" />
          <span className="video-title">WARNING: SCARIEST GAME IN YEARS | Five Nights at Freddy's 2 - Part 1</span>
          <img className="pfp" src="./img/image 9.png" alt="pfp" />
          <span className="extra--info">Markiplier | 58M views | 8 years ago</span>
        </button>
        <button className="video--card video--card3">
          <img className="tn1" src="./img/image 14.png" alt="thumbnail3" />
          <span className="video-title">FREE ROAM FNAF | FAZBEAR NIGHTS</span>
          <img className="pfp" src="./img/image 9.png" alt="pfp" />
          <span className="extra--info">Markiplier | 3.6M views | 5 days ago</span>
        </button>
      </div>
    </section>
  );
}
