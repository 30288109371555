import React from 'react';

export default function OptimizeInfo() {
    return (
        <section id="info" className="info">
            <div className="optimize--background"></div>
            <div className="search-description-container--opt">
                {/* Search container */}
                <div className="search-container--opt">
                    <input type="text" name="search_input" className="text_input--opt" placeholder="What are you looking for?" />
                    <button className="btn btn-search--opt">
                        Search
                    </button>
                </div>
            </div>
            <div className="search--term">Five Nights at Freddy's</div>
            <div className="ai--container">
                <div className="ai--description">
                    <div className="ai--description--box--top"></div>
                    <div className="ai--description--box--body">
                        <img className="gpt" src="./img/image 27.png" alt="gpt" />
                        <span>Markiplier, aka Mark Edward Fischbach, is a prominent YouTuber celebrated for his influential role in the "Five Nights at Freddy's" (FNaF) gaming series. Through his energetic
                            gameplay videos, Markiplier introduced countless viewers to the franchise, spurring its popularity. His humorous reactions to FNaF's jump scares and deep dives into the game's
                            lore contributed to the series' intrigue. Additionally, he fostered a strong fan community by engaging with FNaF enthusiasts, and his charity livestreams featuring FNaF games 
                            further solidified his connection to the series and its fanbase.
                        </span>
                    </div>
                </div>
                <div className="ai--title">AI-Generated Description</div>
            </div>
            <div className="sub--box">
                <div className="sub--title">Sub-Categories</div>
                <div className="sub--column">
                    <div className="sub--row">
                        <button className="filter--button">Minecraft</button>
                        <button className="filter--button">Among Us</button>
                        <button className="filter--button">Five Nights at Freddy's</button>
                        <button className="filter--button">Horror</button>
                        <button className="filter--button">Indie</button>
                        <button className="filter--button">League of Legends</button>
                        <button className="filter--button">Genshin Impact</button>
                        <button className="filter--button">Pewdiepie</button>
                        <button className="filter--button">Adventure</button>
                        <button className="filter--button">Markiplier</button>
                    </div>
                    <div className="sub--row">
                        <button className="filter--button">Free world</button>
                        <button className="filter--button">Multiplayer</button>
                        <button className="filter--button">Playthrough</button>
                        <button className="filter--button">Roblox</button>
                        <button className="filter--button">Collab</button>
                    </div>
                </div>
            </div>
            <div className="optimizer">
                <div className="display--box">
                    <div className='disp--heading'>Your title is optimized by</div>
                    <div className="percent">75%</div>
                    <div className="bar--holder"></div>
                    <div className="progress"></div>
                </div>
                <input type="text" name="search_input" className="text_input--title" placeholder="Start typing in your title" />
            </div>
            <div className="consider">Consider adding these keywords:</div>
            <div className="sugg--column">
                    <div className="sugg--row">
                        <button className="filter--button-s">Scary</button>
                        <button className="filter--button-s">Part</button>
                        <button className="filter--button-s">?!?</button>
                        <button className="filter--button-s">Horror</button>
                      
                    </div>
                    <div className="sub--row">
                        <button className="filter--button-s">Terrifying</button>
                        <button className="filter--button-s">Freddy</button>
                        <button className="filter--button-s">Golden</button>
                        <button className="filter--button-s">Bonny</button>
                        
                    </div>
                    <div className="sub--row">
                        <button className="filter--button-s">Foxy</button>
                        <button className="filter--button-s">Animatronics</button>
                        <button className="filter--button-s">Security</button>
                    </div>
                </div>
        </section>
    );
}
