import React, { useState } from 'react';

export default function Landing() {
  return (
    <section id="heroSection" className="hero--section">
      {/* Black background with opacity */}
      <div className="hero--overlay"></div>

      {/* Background image */}
      <div className="hero--background"></div>

      {/* Content box */}
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="hero--section--title4">How do viewers  
            <br /><span className="primary-text">feel</span> about your 
            <br />content?
          </p>
        </div>

        {/* Container for input and button, and description */}
        <div className="search-description-container">
          {/* Search container */}
          <div className="search-container">
            <input type="text" name="search_input" className="text_input" placeholder="Five Nights at Freddys" />
            <button className="btn btn-search">
              Search
            </button>
          </div>

          {/* Description below the search container */}
          <div className="hero--section--description">
            Trending in <span className="primary-text">YouTube Gaming</span>
          </div>
        </div>
      </div>

      <div className="gradient"></div>
      <div className="line"></div>
      <div className="pitch">Market your content better <span className="primary-text">| Use AI to influence your audience</span></div>
    </section>
  );
}
