import React from 'react';

export default function Categories() {
    return (
        <section id="categories" className="categories">
            <div className="catbar">
                <div className="catbar--title">Categories</div>
                <button className="category">
                    <img className="cat-img" src="./img/gaming.png" alt="catimg" />
                    <span className="cat-name">Gaming</span>
                </button>
                <button className="category">
                    <img className="cat-img" src="./img/movies.png" alt="catimg" />
                    <span className="cat-name">Movies</span>
                </button>
                <button className="category">
                    <img className="cat-img" src="./img/sports.png" alt="catimg" />
                    <span className="cat-name">Sports</span>
                </button>
                <button className="category">
                    <img className="cat-img" src="./img/education.png" alt="catimg" />
                    <span className="cat-name">Education</span>
                </button>
                <button className="category">
                    <img className="cat-img" src="./img/comedy.png" alt="catimg" />
                    <span className="cat-name">Comedy</span>
                </button>
                <button className="category">
                    <img className="cat-img" src="./img/anime.png" alt="catimg" />
                    <span className="cat-name">Anime</span>
                </button>
                <button className="category">
                    <img className="cat-img" src="./img/tech.png" alt="catimg" />
                    <span className="cat-name">Tech</span>
                </button>
            </div>
        </section>
    );
}