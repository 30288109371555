import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const [optimizeSelected, setOptimizeSelected] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    // Set optimizeSelected to true when the route is "/optimize"
    setOptimizeSelected(location.pathname === "/optimize");
  }, [location.pathname]);

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
      <div>
        <p className="text-lg">
          <img className="logo" src="./img/logo4.png" alt="Logo" />
        </p>
      </div>
      <a className={`nav__hamburger ${navActive ? "active" : ""}`} onClick={toggleNav}>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/homescreen" onClick={closeMenu} className="navbar--content">
              Home
            </Link>
          </li>
          <li>
            <Link to="/optimize" onClick={closeMenu} className="navbar--content">
              Optimize
            </Link>
          </li>
        </ul>
      </div>
      <div className="nav--select" style={{ marginLeft: optimizeSelected ? "462px" : "362px" }}></div>
    </nav>
  );
}

export default Navbar;
